<template>
  <div
    :style="{ height: clientHeight - 180 + 'px' }"
    style="overflow-y: scroll"
  >
    <div class="content">
      <div>
        <router-link :to="{ path: '../../../call_center/serviceLook' }">
          <el-button style="padding: 6px 32px">返回</el-button></router-link
        >
      </div>
      <el-table
        :data="tableData"
        style="width: 100%; margin-top: 1.5%"
        :default-sort="{ prop: 'time', order: 'descending' }"
      >
        <el-table-column prop="workNumber" label="工单编号" width="160">
        </el-table-column>
        <el-table-column prop="name" label="通话对象" width="150">
        </el-table-column>
        <el-table-column prop="imei" label="设备号码" width="160">
          <template slot-scope="scope">
            <span
              v-if="
                scope.row.callBackPhone == null || scope.row.callBackPhone == ''
              "
            >
              {{ scope.row.imei }}
            </span>
            <span v-else>{{ scope.row.callBackPhone }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="lastDate" label="剩余时间" sortable width="120">
        </el-table-column>
        <el-table-column prop="priority" label="优先级" sortable width="110">
        </el-table-column>
        <el-table-column
          prop="createDate"
          label="添加时间"
          sortable
          width="200"
        >
        </el-table-column>
        <el-table-column
          prop="describe"
          label="内容"
          :show-overflow-tooltip="true"
          width="350"
        ></el-table-column>
        <el-table-column prop="centerTypeCn" label="呼叫中心">
        </el-table-column>
        <el-table-column prop="userName" label="关联客服" width="110">
        </el-table-column>
        <el-table-column prop="status" label="处理"> </el-table-column>
        <el-table-column prop="Reassignment" label="改派">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.centerType == '1' || scope.row.centerType == '3'"
              style="color: #606266; padding: 6px 15px"
              @click="open(scope.$index, scope.row)"
              >改派</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="bottom-left">
        <p class="bottom-shu">共计{{ totalCount }}条数据</p>
        <div class="pages">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[10, 15, 20, 25, 30]"
            :page-size="pageSize"
            :current-page="pageCount"
            layout="total, prev, pager, next,jumper,sizes"
            :total="totalCount"
            @pagination="getData"
          >
          </el-pagination>
        </div>
      </div>
      <!-- 点击改派的弹框 -->
      <el-dialog :visible.sync="dialogVisible" width="20%">
        <el-table
          :data="tableDataPerson"
          style="width: 100%"
          @cell-click="cellClick"
          @current-change="handleCurrent"
          :row-class-name="tableRowClassName"
        >
          <el-table-column prop="name" label="关联客服"> </el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancel">取 消</el-button>
          <el-button type="primary" @click="quietChange">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { api } from "../../api1/config";
import { apiShout } from "../../api1/configShout";
export default {
  data() {
    return {
      clientHeight: document.body.clientHeight,
      currentPage1: 1,
      pageSize: 10,
      totalCount: 0,
      pageCount: 1,
      tableData: [],
      tableDataPerson: [],
      dialogVisible: false,
      serviceId: "", //关联客服id
      workOrder: null,
      centerTypeID: "",
      userNames: "",
    };
  },
  created() {
    this.getData();
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight;
        that.clientHeight = window.screenHeight;
      })();
    };
  },
  watch: {
    clientHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.clientHeight = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.clientHeight);
          that.timer = false;
        }, 400);
      }
    },
  },
  methods: {
    //列表查询
    getData() {
      apiShout
        .getWorkLists({ pageCount: this.pageCount, pageSize: this.pageSize })
        .then((res) => {
          if (res.data.code == 200) {
            console.log(res, "查询工单列表返回数据");
            this.tableData = res.data.result.data;
            for (let i = 0; i < this.tableData.length; i++) {
              if (this.tableData[i].lastDate != 0) {
                this.tableData[i].lastDate =
                  this.tableData[i].lastDate + "分钟";
              } else {
                this.tableData[i].lastDate = "已到期";
              }
            }
            this.totalCount = res.data.result.page.totalCount;
            this.pageSize = res.data.result.page.pageSize;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getData();
    },
    //当前在第几页
    handleCurrentChange(pageCount) {
      this.pageCount = pageCount;
      this.getData();
    },
    // 改派
    open(index, row) {
      console.log(index, row, "查询列表数据");
      this.workOrder = row.id;
      this.centerTypeID = row.centerType;
      console.log(row.centerType, "登录客服");
      this.userNames = row.userName;
      //查询机构下客服类型所有客服接口
      api.getallTypeUser({ serviceType: row.centerType }).then((res) => {
        if (res.data.code == 200) {
          this.tableDataPerson = res.data.result;
        } else {
          this.$message.error(res.data.msg);
        }
      });
      this.dialogVisible = true;
    },
    handleCurrent(val) {
      this.currentRow = val;
    },
    //关联人员弹框
    cellClick(row) {
      console.log("===========", row);
      this.serviceId = row.id;
      console.log(this.serviceId, "123123123");
      this.userNames = row.name;
      if (row.name === this.userNames) {
        return "warning-row";
      }
    },
    tableRowClassName({ row }) {
      console.log(row, "row,row");
      if (row.name === this.userNames) {
        return "warning-row";
      }
    },
    //确定
    quietChange() {
      this.$confirm("确定改派此条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (this.centerTypeID == "1") {
            let obj = {
              userId: this.serviceId,
              id: this.workOrder,
            };
            console.log(obj, "查看数据");
            apiShout.getUpdateWorkOrder(obj).then((res) => {
              if (res.data.code == 200) {
                this.$message({
                  type: "success",
                  message: "改派成功!",
                });
                this.dialogVisible = false;
                this.getData();
              } else {
                this.$message.error(res.data.msg);
              }
            });
          } else if (this.centerTypeID == "3") {
            let obj = {
              userId: this.serviceId,
              status: "0",
              id: this.workOrder,
            };
            apiShout.getUpdateHotOrder(obj).then((res) => {
              if (res.data.code == 200) {
                this.$message({
                  type: "success",
                  message: "改派成功!",
                });
                this.dialogVisible = false;
                this.getData();
              } else {
                this.$message.error(res.data.msg);
              }
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    //取消
    cancel() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  padding: 40px 30px 0px;
}
.bottom-left {
  margin-top: 2%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}
/deep/.el-table .warning-row {
  background: #f09c83 !important;
}
/deep/.el-dialog__headerbtn {
  top: 10px;
}
/deep/ .el-table tbody tr:hover > td {
  background-color: #f5f7fa;
}
/deep/.el-table td,
.el-table th {
  padding: 12px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: center !important;
}
/deep/.el-table .cell {
  text-align: center !important;
}
/deep/.el-table td,
.el-table th.is-leaf {
  border-bottom: none !important;
}
/deep/.el-table thead tr th.is-leaf {
  border: none;
  border-right: none;
}
/deep/.el-form-item__label {
  color: #909399;
}
</style>